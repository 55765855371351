@import "~/src/styles/functions";

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: rem(7px);
  position: relative;
  overflow: hidden;
  height: rem(80px);
  transition: 0.3s;

  font-family: HeadingNow;
  text-transform: uppercase;
  border-radius: rem(30px);
  -webkit-border-radius: rem(30px);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  color: white;

  &:hover {
    // transform: translateX(rem(5px));
  }

  .background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: hsla(209, 48%, 49%, 0.3);
    backdrop-filter: blur(rem(5px));
    border-radius: rem(30px);
    -webkit-border-radius: rem(30px);
    z-index: 2;
  }

  .backgroundMatte {
    position: absolute;
    inset: 0 0 0 0;
    z-index: 2;
    background-color: hsla(209, 48%, 49%, 0.3);
    backdrop-filter: blur(rem(5px));
    border-radius: rem(30px);
    -webkit-border-radius: rem(30px);
  }

  .imageWrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: rem(30px);
    -webkit-border-radius: rem(30px);
  }

  .date {
    position: relative;
    z-index: 3;
    font-size: rem(23px);
    line-height: 90%;
  }

  .chapterNumber {
    position: relative;
    z-index: 3;
    font-size: rem(12px);
    line-height: 90%;
  }

  .link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 4;
  }
}
