@import "~/src/styles/mixins";
@import "~/src/styles/variables";
@import "~/src/styles/functions";

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 rem(10px);
  height: rem(55px);
  background-color: hsla(32, 100%, 62%, 1);
  color: white;
  border-radius: rem(20px);
  transition: 0.3s;

  font-family: HeadingNow;
  font-size: rem(30px);
  text-transform: uppercase;

  @media (max-width: $breakPoint3) {
    height: rem(46px);
    border-radius: rem(13px);
    font-size: rem(23px);
  }

  @include desktop() {
    &:hover {
      transform: translateY(rem(-2px));
    }
  }

  &.blue {
    background-color: hsla(209, 20%, 90%, 1);
    color: hsla(208, 15%, 46%, 1);
  }
}
