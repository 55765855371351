@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-VariableFont_wght.ttf") format("truetype-variations");
}
@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: url("../fonts/Roboto-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: url("../fonts/Roboto-Medium.ttf") format("opentype");
}


// @font-face {
//   font-family: "HeadingNow";
//   src: url("/fonts/HeadingNowTrial-56Bold.ttf") format("opentype");
// }
@font-face {
  font-family: "HeadingNow";
  src: url("../fonts/HeadingNow-56Bold.otf") format("opentype");
}