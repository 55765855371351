@import "~/src/styles/functions";

.field {
  background-color: transparent;
  border-radius: rem(25px);
  position: relative;
  height: rem(70px);
  width: rem(150px);
  max-width: 95vw;
  transition: 0.3s;
  overflow: hidden;

  &.active {
    background-color: white;
    width: rem(347px);

    .button {
      width: rem(96px);
    }
  }

  @media (max-width: rem(400px)) {
    height: rem(60px);
    width: rem(120px);
    border-radius: rem(20px);
  }

  .button {
    height: 100%;
    // width: 100%;
    width: rem(150px);
    color: white;
    border-radius: rem(25px);
    overflow: hidden;
    background-color: hsla(32, 100%, 54%, 1);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;

    @media (max-width: rem(400px)) {
      border-radius: rem(20px);
      width: rem(120px);
    }
  }
}

.input {
  position: absolute;
  left: rem(22.5px);
  top: 50%;
  transform: translateY(-50%);
  color: hsla(208, 15%, 46%, 1);
  background-color: transparent;
  border: none;
  font-family: Roboto;
  font-weight: 400;
  font-size: rem(18px);
  max-width: rem(230px);
}

.buttonText {
  font-family: HeadingNow;
  text-transform: uppercase;
  font-size: rem(35px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.3s;

  &.active {
    opacity: 1;
  }

  @media (max-width: rem(400px)) {
    font-size: rem(25px);
  }
}
