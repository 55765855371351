@import "functions";

@mixin link() {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

@mixin desktop() {
  @media (min-width: rem(760px)) {
    @content;
  }
}
