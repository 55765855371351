@use "~/src/styles/variables" as v;
@import "~/src/styles/functions";

.bannerPage {
  width: 100%;
  height: var(--height, 100vh);
  position: relative;
  overflow-y: auto;
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.centerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  height: var(--height, 100vh);
  max-height: calc(var(--height, 100vh) - 20%);

  @media (max-width: 400px) {
    justify-content: initial;
    
  }
}

.logoWrapper {
  position: relative;
  width: 116px;
  height: 68px;
  min-width: 116px;
  min-height: 68px;

  @media (max-width: rem(400px)) {
    width: 98px;
    height: 60px;
    min-width: 98px;
    min-height: 60px;
  }
}

.cube {
  position: relative;
  width: calc(100vw - 40px);
  aspect-ratio: 1;
  right: 1px;
  max-width: 360px;
  max-height: calc(var(--height, 100vh) / 2);
  animation-name: floating;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  margin-top: 35px;
  margin-bottom: 45px;
}

.footer {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  width: 100%;
  max-width: 460px;
  padding: 0 20px;
}

@keyframes floating {
  0% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(5px);
  }
}

.loadingStub {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsla(204, 71%, 62%, 0.95);
  backdrop-filter: blur(10px);
  transition: 0.3s;

  &.hiding {
    opacity: 0;
    pointer-events: 0;
  }

  &.hidden:not(.hiding) {
    display: none;
    z-index: -1;
    opacity: 0;
    pointer-events: 0;
  }
}
.loaderWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.termsOfUseWrapper {
  margin-top: 12px;
  max-width: 325px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;
  transition: 0.3s;
  pointer-events: none;
  opacity: 0;

  &.shown{
    pointer-events: all;
    opacity: 1;
  }
}

.termsOfUse {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  color: hsla(0, 0%, 100%, 0.75);

  .link {
    color: inherit;
    text-decoration: underline;
  }
}

.checkbox{
  width: 30px;
  min-width: 30px;
  height: 30px;
  background-color: hsla(0, 0%, 100%, 0.5);
  position: relative;
  border-radius: 10px;
  border: 1px solid hsla(0, 0%, 100%, 0.3);

  .check{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 85%;
    object-fit: contain;
    opacity: 0;
    transition: 0.3s;

    &.active{
      opacity: 1;
    }
  }
}